import { EventEmitter } from '@angular/core';

export class BaseInsideModal {
  public closeEvent: EventEmitter<any> = new EventEmitter();
  public payloadData: any;

  public readonly contentClass: string;
  public readonly dialogActionsBlockClass: string;
  public readonly dialogInfoMessageBlockClass: string;
}
